import "../styles/about.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import PaymentController from "../services/payment-controller";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";

export default function Levels(props) {
  const { t } = useTranslation();
  const localizer = t;

  return (
    <div>
      <h3>سطح بندی مشتریان :</h3>
    </div>
  );
}
