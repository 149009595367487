import "../styles/edit-levels.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_BASE_URL, APP_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";

export default function EditLevels() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	//
	const [levelId, setLevelId] = useState("");
	const [name, setName] = useState("");
	const [title, setTitle] = useState("");
	const [summary, setSummary] = useState("");
	const [notes, setNotes] = useState("");
	const [minDecile, setMinDecile] = useState("");
	const [maxDecile, setMaxDecile] = useState("");
	const [offPercent, setOffPercent] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [levels, setLevels] = useState([]);
	const [images, setImages] = useState([]);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [isUploading, setIsUploading] = useState(false);
	//
	//> useEffect
	useEffect(() => {
		getData();
	}, []);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);

			const levelsResult = await adminController.getLevels();
			if (levelsResult.isSuccess === false) throw levelsResult.message;

			let lastRowNumber = 0;
			levelsResult.value.map((item) => (item.index = ++lastRowNumber));

			setLevels(levelsResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> resetLevel
	const resetLevel = async function () {
		try {
			setPageType("edit");

			setLevelId("00000000-0000-0000-0000-000000000000");
			setName("");
			setTitle("");
			setSummary("");
			setNotes("");
			setMinDecile(0);
			setMaxDecile(0);
			setOffPercent("");
			setSortOrder("");

			setImages([]);
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editLevel
	const editLevel = async function (objLevel) {
		try {
			setPageType("edit");

			setLevelId(objLevel.levelId);
			setName(objLevel.name);
			setTitle(objLevel.title);
			setSummary(objLevel.summary);
			setNotes(objLevel.notes);
			setMinDecile(objLevel.minDecile);
			setMaxDecile(objLevel.maxDecile);
			setOffPercent(objLevel.offPercent);
			setSortOrder(objLevel.sortOrder);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateLevel
	const updateLevel = async function () {
		try {
			let errors = [];

			if (!name) errors.push("نام سطح را وارد نمایید");
			if (!title) errors.push("عنوان سطح را وارد نمایید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت سطم مشتری",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateLevelResult = await adminController.updateLevel(
				levelId,
				name,
				title,
				summary,
				notes,
				minDecile,
				maxDecile,
				offPercent,
				sortOrder
			);
			if (updateLevelResult.isSuccess === false)
				throw updateLevelResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت سطم مشتری",
				detail: localizer("ثبت اطلاعات سطم مشتری با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> uploadFile
	const selectFileForUpload = function () {
		document.querySelector("#frm_fileImage").click();
	};
	//> uploadFile
	const uploadFile = async function () {
		try {
			setIsUploading(true);
			var uploadResult = await adminController.addLevelImage(
				levelId,
				document.querySelector("#frm_fileImage").files[0]
			);
			if (uploadResult.isSuccess === false) throw uploadResult.message;
			let oldImages = images;
			images.push({
				fileId: uploadResult.value,
				imageUrl: `File/Thumbnail?fileId=${uploadResult.value}&w=150&h=150`,
			});
			setImages(oldImages);
		} catch (error) {
			alert(error.toString());
		}
		setIsUploading(false);
	};

	//> cancelLevel
	const cancelLevel = function () {
		setPageType("list");
	};
	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="levels">
				{pageType === "list" && (
					<>
						<div>
							<Button
								label="ثبت سطم مشتری جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetLevel()}
							/>
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>نام سطح</th>
									<th>عنوان</th>
									<th>حداقل دهک</th>
									<th>حداکثر دهک</th>
									<th>درصد تخفیف</th>
									<th>ترتیب نمایش</th>
								</tr>
							</thead>
							<tbody>
								{levels.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editLevel(item)}
											>
												{item.name}
											</a>
										</td>
										<td>{item.title}</td>
										<td>{item.minDecile}</td>
										<td>{item.maxDecile}</td>
										<td>{item.SortOrder}</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>نام سطح</label>
								<InputText
									value={name}
									onChange={(e) => setName(e.target.value)}
									required
								/>
							</div>
							<div>
								<label>عنوان</label>
								<InputText
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>خلاصه</label>
								<InputText
									value={summary}
									onChange={(e) => setSummary(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>شرح</label>
								<Editor
									value={notes}
									onTextChange={(e) => setNotes(e.htmlValue)}
									style={{ height: "320px" }}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>شروع دهک</label>
								<InputText
									value={minDecile}
									onChange={(value) => setMinDecile(value)}
								/>
							</div>
							<div>
								<label>پایان دهک</label>
								<InputText
									value={maxDecile}
									onChange={(value) => setMaxDecile(value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>درصد تخفیف</label>
								<InputText
									value={offPercent}
									onChange={(value) => setOffPercent(value)}
								/>
							</div>
							<div>
								<label>ترتیب نمایش</label>
								<InputText
									value={sortOrder}
									onChange={(value) => setSortOrder(value)}
								/>
							</div>
						</div>

						<div>
							<input
								type="file"
								accept="images"
								id="frm_fileImage"
								onChange={() => uploadFile()}
							/>
							<ul className="images">
								<li onClick={() => selectFileForUpload()}>
									{isUploading === true && <Skeleton></Skeleton>}
									{isUploading === false && (
										<i className={PrimeIcons.PLUS_CIRCLE}></i>
									)}
								</li>
								{images.map((item) => (
									<li>
										<img src={API_BASE_URL + item.imageUrl} />
										<a className={PrimeIcons.TRASH}></a>
									</li>
								))}
							</ul>
						</div>

						<div className="buttons">
							<Button
								label="ثبت سطح مشتریان"
								onClick={() => updateLevel("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelLevel()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
