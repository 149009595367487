import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function PaymentController() {
    const token = getToken();

    //> viewPayment
    this.viewPayment = async function () {
        let result = { isSuccess: true, message: "Success", value: null };
        try {
            const response = await axios.request({
                method: 'get',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}Payment/ViewPayment`,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: null
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }

    //> doPayment
    this.doPayment = async function (paymentId) {
        let result = { isSuccess: true, message: "Success", value: null };
        try {
            const response = await axios.request({
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}Payment/DoPayment`,
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                data: JSON.stringify({
                    "paymentId": paymentId
                })
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }

    //> getPayment
    this.getPayment = async function (paymentId) {
        let result = { isSuccess: true, message: "Success", value: null };
        try {
            const response = await axios.request({
                method: 'get',
                maxBodyLength: Infinity,
                url: `${API_BASE_URL}Payment/GetPayment?paymentId=${paymentId}`,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: null
            });
            result.value = response.data;
        }
        catch (error) {
            result.isSuccess = false;
            result.message = error.toString();
            if (error.response && error.response.status === 401)
                result.message = error.response.data;
        }
        return result;
    }
    //
}