import "../styles/edit-stores.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_BASE_URL, APP_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";

export default function EditStores() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isDownloading, setIsDownloading] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [searchStoreType, setSearchStoreType] = useState("");
	//
	const [stores, setStores] = useState([]);
	const [images, setImages] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	//> Properties
	const [storeId, setStoreId] = useState(null);
	const [name, setName] = useState(null);
	const [rowNumber, setRowNumber] = useState(0);
	const [offPercent, setOffPercent] = useState(0);
	const [summary, setSummary] = useState(null);
	const [notes, setNotes] = useState(null);
	const [dayOfWork, setDayOfWork] = useState(null);
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState(0);
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [address, setAddress] = useState(null);
	const [tel, setTel] = useState(null);
	const [otherTel, setOtherTel] = useState(null);
	const [website, setWebsite] = useState(null);
	const [telegram, setTelegram] = useState(null);
	const [instagram, setInstagram] = useState(null);
	const [subCategoryName, setSubCategoryName] = useState(null);
	const [parentCategoryName, setParentCategoryName] = useState(null);
	const [stateName, setStateName] = useState(null);
	const [cityName, setCityName] = useState(null);
	const [area, setArea] = useState(null);
	const [storeType, setStoreType] = useState(null);
	const [userId, setUserId] = useState(null);
	const [dailyCapacity, setDailyCapacity] = useState(0);
	//
	const storeTypes = [
		{ label: "اختصاصی", value: "Special" },
		{ label: "اعتباری", value: "Credit" },
		{ label: "سایر", value: "Other" },
	];
	//> useEffect
	useEffect(() => {
		getData();
	}, [searchStoreType, first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			const storesResult = await adminController.getStores(
				searchStoreType,
				Math.floor(first / 12) + 1,
				12
			);
			if (storesResult.isSuccess === false) throw storesResult.message;

			storesResult.value.map((item) => {
				item.startDate = new Date(item.startDate);
				item.endDate = new Date(item.endDate);
			});

			let lastRowNumber = first - 1;
			storesResult.value.map((item) => (item.index = ++lastRowNumber));

			setStores(storesResult.value);
			setTotalRecords(storesResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> changeSearchStoreType
	const changeSearchStoreType = function (status) {
		setSearchStoreType(status);
		getData();
	};
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> resetStore
	const resetStore = async function () {
		try {
			setPageType("edit");
			setStoreId("00000000-0000-0000-0000-000000000000");
			setName("");
			setRowNumber(0);
			setOffPercent(0);
			setSummary("");
			setNotes("");
			setDayOfWork("");
			setStartTime(0);
			setEndTime(0);
			setLatitude("");
			setLongitude("");
			setAddress("");
			setTel("");
			setOtherTel("");
			setWebsite("");
			setTelegram("");
			setInstagram("");
			setSubCategoryName("");
			setParentCategoryName("");
			setStateName("");
			setCityName("");
			setArea("");
			setStoreType("");
			setUserId(null);
			setDailyCapacity(0);
			setImages([]);
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editStore
	const editStore = async function (objStore) {
		try {
			setPageType("edit");

			setStoreId(objStore.storeId);
			setName(objStore.name);
			setRowNumber(objStore.rowNumber);
			setOffPercent(objStore.offPercent);
			setSummary(objStore.summary);
			setNotes(objStore.notes);
			setDayOfWork(objStore.dayOfWork);
			setStartTime(objStore.startTime);
			setEndTime(objStore.endTime);
			setLatitude(objStore.latitude);
			setLongitude(objStore.longitude);
			setAddress(objStore.address);
			setTel(objStore.tel);
			setOtherTel(objStore.otherTel);
			setWebsite(objStore.website);
			setTelegram(objStore.telegram);
			setInstagram(objStore.instagram);
			setSubCategoryName(objStore.subCategoryName);
			setParentCategoryName(objStore.parentCategoryName);
			setStateName(objStore.stateName);
			setCityName(objStore.cityName);
			setArea(objStore.area);
			setStoreType(objStore.storeType);
			setUserId(objStore.userId);
			setDailyCapacity(objStore.dailyCapacity);
			setImages(objStore.images);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateStore
	const updateStore = async function () {
		try {
			let errors = [];

			if (!storeType) errors.push("نوع فروشگاه را وارد نمایید");
			if (!name) errors.push("نام فروشگاه را وارد نمایید");
			if (!address) errors.push("آدرس را وارد نمایید");
			if (!tel) errors.push("تلفن را وارد نمایید");
			if (!stateName) errors.push("استان را وارد نمایید");
			if (!cityName) errors.push("شهر را وارد نمایید");
			if (!area) errors.push("منطقه را وارد نمایید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت فروشگاه",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateStoreResult = await adminController.updateStore(
				storeId,
				name,
				rowNumber,
				offPercent,
				summary,
				notes,
				dayOfWork,
				startTime,
				endTime,
				latitude,
				longitude,
				address,
				tel,
				otherTel,
				website,
				telegram,
				instagram,
				subCategoryName,
				parentCategoryName,
				stateName,
				cityName,
				area,
				storeType,
				userId,
				dailyCapacity
			);
			if (updateStoreResult.isSuccess === false)
				throw updateStoreResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت فروشگاه",
				detail: localizer("ثبت اطلاعات فروشگاه با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> uploadFile
	const selectFileForUpload = function () {
		document.querySelector("#frm_fileImage").click();
	};
	//> uploadFile
	const uploadFile = async function () {
		try {
			setIsUploading(true);
			var uploadResult = await adminController.addStoreImage(
				storeId,
				document.querySelector("#frm_fileImage").files[0]
			);
			if (uploadResult.isSuccess === false) throw uploadResult.message;
			let oldImages = images;
			images.push({
				fileId: uploadResult.value,
				imageUrl: `File/Thumbnail?fileId=${uploadResult.value}&w=150&h=150`,
			});
			setImages(oldImages);
		} catch (error) {
			alert(error.toString());
		}
		setIsUploading(false);
	};

	//> cancelStore
	const cancelStore = function () {
		setPageType("list");
	};
	//> downloadExcel
	const downloadExcel = async function () {
		try {
			setIsDownloading(true);

			let isStore = false;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				isStore = urlParams.get("isStore");
			}

			const exportResult = await adminController.exportStores(
				isStore,
				searchStoreType
			);
			if (exportResult.isSuccess === false) throw exportResult.message;
			document
				.querySelector("#excel")
				.setAttribute(
					"href",
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
						exportResult.value
				);
			document.querySelector("#excel").click();
		} catch (error) {
			alert(error.toString());
		}
		setIsDownloading(false);
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="stores">
				{pageType === "list" && (
					<>
						<ul className="storetype">
							<li
								className={searchStoreType === "" ? "active" : ""}
								onClick={() => changeSearchStoreType("")}
							>
								همه
							</li>
							<li
								className={
									searchStoreType === "Special" ? "active special" : "special"
								}
								onClick={() => changeSearchStoreType("Special")}
							>
								مراکز اختصاصی
							</li>
							<li
								className={
									searchStoreType === "Credit" ? "active credit" : "credit"
								}
								onClick={() => changeSearchStoreType("Credit")}
							>
								اعتباری
							</li>
							<li
								className={
									searchStoreType === "Other" ? "active other" : "other"
								}
								onClick={() => changeSearchStoreType("Other")}
							>
								سایر فروشگاه ها
							</li>
						</ul>
						<div>
							<Button
								label="ثبت فروشگاه جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetStore()}
							/>
							<Button
								label="خروجی اکسل"
								icon={PrimeIcons.FILE_EXCEL}
								onClick={() => downloadExcel()}
								loading={isDownloading}
							/>
							<a id="excel" style={{ display: "none" }} target="_blank" />
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>نوع فروشگاه</th>
									<th>نام فروشگاه</th>
									<th>استان</th>
									<th>شهر</th>
									<th>تلفن</th>
									<th>تخفیف</th>
									<th>ظرفیت پذیرش</th>
								</tr>
							</thead>
							<tbody>
								{stores.map((item) => (
									<tr data-storetype={item.storeType}>
										<td>{item.index}</td>
										<td>{item.storeType}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editStore(item)}
											>
												{item.name}
											</a>
										</td>
										<td>{item.stateName}</td>
										<td>{item.cityName}</td>
										<td>{item.tel}</td>
										<td>{item.offPercent}</td>
										<td>{item.dailyCapacity}</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>نوع فروشگاه</label>
								<Dropdown
									options={storeTypes}
									value={storeType}
									onChange={(e) => setStoreType(e.value)}
								/>
							</div>
							<div>
								<label>نام فروشگاه</label>
								<InputText
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>استان</label>
								<InputText
									value={stateName}
									onChange={(e) => setStateName(e.target.value)}
								/>
							</div>
							<div>
								<label>شهرستان</label>
								<InputText
									value={cityName}
									onChange={(e) => setCityName(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>منطقه</label>
								<InputText
									value={area}
									onChange={(e) => setArea(e.target.value)}
								/>
							</div>
							<div>
								<div>
									<label>موبایل مالک فروشگاه</label>
									<InputText
										value={userId}
										onChange={(e) => setUserId(e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className="full">
							<div>
								<label>آدرس</label>
								<InputText
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>توضیحات مختصر</label>
								<InputText
									value={summary}
									onChange={(e) => setSummary(e.target.value)}
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>توضیحات کامل</label>
								<Editor value={notes} onTextChange={(e) => setNotes(e.htmlValue)} style={{ height: '320px' }} />
							</div>
						</div>
						<div>
							<div>
								<label>تلفن اصلی</label>
								<InputText
									value={tel}
									onChange={(e) => setTel(e.target.value)}
								/>
							</div>
							<div>
								<label>تلفن دیگر</label>
								<InputText
									value={otherTel}
									onChange={(e) => setOtherTel(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>آدرس سایت</label>
								<InputText
									value={website}
									onChange={(e) => setWebsite(e.target.value)}
								/>
							</div>
							<div>
								<label>تلگرام</label>
								<InputText
									value={telegram}
									onChange={(e) => setTelegram(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>اینستاگرام</label>
								<InputText
									value={instagram}
									onChange={(e) => setInstagram(e.target.value)}
								/>
							</div>
							<div>
								<label>ظرفیت پذیرش روزانه</label>
								<InputText
									value={dailyCapacity}
									onChange={(e) => setDailyCapacity(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>دسته بندی اصلی</label>
								<InputText
									value={parentCategoryName}
									onChange={(e) => setParentCategoryName(e.target.value)}
								/>
							</div>
							<div>
								<label>دسته بندی فرعی</label>
								<InputText
									value={subCategoryName}
									onChange={(e) => setSubCategoryName(e.target.value)}
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>روزهای کاری</label>
								<InputText
									value={dayOfWork}
									onChange={(e) => setDayOfWork(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>ساعت شروع</label>
								<InputText
									value={startTime}
									onChange={(e) => setStartTime(e.target.value)}
								/>
							</div>
							<div>
								<label>ساعات پایان</label>
								<InputText
									value={endTime}
									onChange={(e) => setEndTime(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>طول جغرافیایی</label>
								<InputText
									value={longitude}
									onChange={(e) => setLongitude(e.target.value)}
								/>
							</div>
							<div>
								<label>عرض جغرافیایی</label>
								<InputText
									value={latitude}
									onChange={(e) => setLatitude(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<input
								type="file"
								accept="images"
								id="frm_fileImage"
								onChange={() => uploadFile()}
							/>
							<ul className="images">
								<li onClick={() => selectFileForUpload()}>
									{isUploading === true && <Skeleton></Skeleton>}
									{isUploading === false && (
										<i className={PrimeIcons.PLUS_CIRCLE}></i>
									)}
								</li>
								{images.map((item) => (
									<li>
										<img src={API_BASE_URL + item.imageUrl} />
										<a className={PrimeIcons.TRASH}></a>
									</li>
								))}
							</ul>
						</div>

						<div className="buttons">
							<Button
								label="ثبت فروشگاه"
								onClick={() => updateStore("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelStore()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
