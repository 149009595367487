import "../styles/payment.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import PaymentController from "../services/payment-controller";
import { getToken } from "../utils/utility";
import { useNavigate } from "react-router-dom";

export default function Payment(props) {
  const { t } = useTranslation();
  const localizer = t;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [mobile, setMobile] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [totalAmount, setTotalAmount] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //
  const [method, setMethod] = useState(null);
  const [url, setUrl] = useState(null);
  const [formData, setFormData] = useState({});

  const paymentController = new PaymentController();
  let counter = 0;

  useEffect(() => {
    getData();
  }, []);

  const getData = async function () {
    try {
      if (!getToken()) return;
      if (window.isBusy === true) return;
      setIsLoading(true);
      window.isBusy = true;
      var result = await paymentController.viewPayment();
      if (result.isSuccess === false) throw result.message;
      setPaymentId(result.value.paymentId);
      setMobile(result.value.mobile);
      setNationalId(result.value.nationalId);
      setTotalAmount(result.value.totalAmount);
    } catch (error) {
      setErrorMessage(error.toString());
    }
    setIsLoading(false);
    window.isBusy = false;
  };

  const sendPayment = async function () {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const result = await paymentController.doPayment(paymentId);
      if (result.isSuccess === false) throw result.message;
      setMethod(result.value.method);
      setUrl(result.value.url);
      setFormData(result.value.formData);
      setTimeout(() => {
        document.querySelector("#paymentForm").submit();
      }, 2000);
    } catch (error) {
      setErrorMessage(error.toString());
    }
    setIsLoading(false);
  };

  //>
  if (!getToken()) {
    //navigate("/login?returnUrl=/payment", { replace: true });
    window.location = "/#/login?returnUrl=/payment";
    return <></>;
  }
  return (
    <article className="payment">
      <div className="header">{localizer("Payment page")}</div>
      <div className="invoice">
        <ul>
          <li>
            <label>{localizer("Mobile number")}</label>
            <span>{mobile}</span>
          </li>
          <li>
            <label>{localizer("National code")}</label>
            <span>{nationalId}</span>
          </li>
          <li>
            <label>دهک بندی شما</label>
            <span>دهک 5</span>
            <b>آزمایشی</b>
          </li>
          <li>
            <label>سطح امتیاز</label>
            <span>طلایی</span>
            <b>آزمایشی</b>
          </li>
        </ul>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>{localizer("Desription")}</th>
              <th>{localizer("Amount")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{(++counter).toLocaleString("fa-IR")}</td>
              <td>
                {localizer("Amount of customers clup for 1 year")}
                <b>آزمایشی</b>
              </td>
              <td>
                {(70000).toLocaleString("fa-IR")} {localizer("Rial")}
              </td>
            </tr>
            <tr>
              <td>{(++counter).toLocaleString("fa-IR")}</td>
              <td>
                {localizer("Percent of discount")}
                <b>آزمایشی</b>
              </td>
              <td>
                {(50).toLocaleString("fa-IR")} {localizer("Percent")}
              </td>
            </tr>
            <tr>
              <td>{(++counter).toLocaleString("fa-IR")}</td>
              <td>
                {localizer("Amount of discount")}
                <b>آزمایشی</b>
              </td>
              <td>
                {" "}
                {(35000).toLocaleString("fa-IR")} {localizer("Rial")}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td>
                {localizer("Payable amount")}
                <b>آزمایشی</b>
              </td>
              <td>
                {(35000).toLocaleString("fa-IR")} {localizer("Rial")}
              </td>
            </tr>
          </tfoot>
        </table>
        <div>
          <Button
            label={localizer("Payment")}
            onClick={() => sendPayment()}
            loading={isLoading}
          />
        </div>
      </div>
      <div>
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
      </div>

      <form id="paymentForm" method={method} action={url}>
        {Object.keys(formData).map((item) => (
          <input type="hidden" name={item} value={formData[item]} />
        ))}
      </form>
    </article>
  );
}
