import "../styles/add-new-queue.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import ServiceController from "../services/service-controller";
import { PrimeIcons } from "primereact/api";
import { Map } from "@neshan-maps-platform/ol";
import NeshanMap from "@neshan-maps-platform/react-openlayers";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Image } from "primereact/image";
import { getToken } from "../utils/utility";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useNavigate } from "react-router-dom";

export default function AddNewQueue() {
	const { t } = useTranslation();
	const localizer = t;
	const navigate = useNavigate();
	const toast = useRef(null);
	const stepperRef = useRef(null);
	const serviceController = new ServiceController();
	const mapRef = useRef(null);
	//> Properties
	const [levelType, setLevelType] = useState("cities");
	const [currentCityName, setCurrentCityName] = useState(null);
	const [cities, setCities] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [pageType, setPageType] = useState("list");
	const [roomDetails, setRoomDetails] = useState({});
	const [startDate, setStartDate] = useState(null);
	const [totalDays, setTotalDays] = useState(1);
	//> Family properties
	const [families, setFamiles] = useState([]);
	const [familyType, setFamilyType] = useState("همسر");
	const [familyName, setFamilyName] = useState(null);
	const [familyNationalId, setFamilyNationalId] = useState(null);

	//> Commpon Properties
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	let rowNumber = 0;
	let familyRowNumber = 0;

	//> services
	const [services, setServices] = useState([
		{ name: "شام", detail: "شام نوع 1", price: 3000000 },
		{ name: "شام", detail: "شام نوع 2", price: 6000000 },
		{ name: "شام", detail: "شام نوع 3", price: 7000000 },
		{ name: "صبحانه", detail: "صبحانه نوع 1", price: 1000000 },
		{ name: "صبحانه", detail: "صبحانه نوع 2", price: 2000000 },
		{ name: "صبحانه", detail: "صبحانه نوع 3", price: 3000000 },
		{ name: "ناهار", detail: "ناهار نوع 1", price: 2000000 },
		{ name: "ناهار", detail: "ناهار نوع 2", price: 3000000 },
		{ name: "ناهار", detail: "ناهار نوع 3", price: 5000000 },
	]);

	//> totalServices
	const [totalServices, setTotalServices] = useState([]);

	//> updateServiceTotal
	const updateServiceTotal = function (rowNumber, value) {
		if (value < 0 || value > families.length + 1) return;
		let newtotalServices = totalServices.map((item) => {
			return item.rowNumber === rowNumber ? { ...item, total: value } : item;
		});
		setTotalServices(newtotalServices);
	};

	//> updateServiceDetail
	const updateServiceDetail = function (rowNumber, value, options) {
		let price = options.find((item) => item.name === value).price;
		let newtotalServices = totalServices.map((item) => {
			return item.rowNumber === rowNumber
				? { ...item, detail: value, price: price }
				: item;
		});
		setTotalServices(newtotalServices);
	};

	//> getData
	const getData = async function () {
		const citiesResult = await serviceController.getCities();
		setCities(citiesResult.value);
	};

	//> selectCity
	const selectCity = async function (cityName) {
		setCurrentCityName(cityName);
		stepperRef.current.nextCallback();
		const roomsResult = await serviceController.getRooms(currentCityName);
		setRooms(roomsResult.value);
		setLevelType("rooms");
	};

	//> selectRoom
	const selectRoom = async function (roomId) {
		setPageType("details");
		const roomDetailsResult = await serviceController.getRoomDetails(roomId);
		//>for test
		roomDetailsResult.value.city = currentCityName;
		//<
		setRoomDetails(roomDetailsResult.value);
		//window.myOpenLayer = mapRef ;
		//TODO : must be implement later
		//mapRef.current?.map.getView().setCenter([roomDetailsResult.value.longitude ,roomDetailsResult.value.latitude ]);
	};

	//> cancelRoom
	const cancelRoom = function () {
		setPageType("list");
	};

	//> confirmRoom
	const confirmRoom = function () {
		setPageType("list");
		stepperRef.current.nextCallback();
	};

	//> confirmDate
	const confirmDate = function () {
		if (totalDays > 3 || totalDays < 1) {
			toast.current.show({
				severity: "warn",
				summary: "انتخاب تاریخ و مدت اقامت",
				detail: "مدت اقامت باید بین یک تا سه شب انتخاب شود",
			});
			return;
		}

		let tempTotalServices = [
			{ name: "صبحانه", options: [] },
			{ name: "ناهار", options: [] },
			{ name: "شام", options: [] },
		];

		tempTotalServices.map((item) => {
			services
				.filter((sItem) => sItem.name === item.name)
				.map((sItem) => {
					item.options.push({ name: sItem.detail, price: sItem.price });
				});
		});

		let _totalServices = [];

		let rowNumber = 0;
		// شام روز اول
		let lastItem = tempTotalServices[2];
		_totalServices.push({
			rowNumber: ++rowNumber,
			day: "روز " + getDayTitle(0),
			name: "شام",
			detail: lastItem.options[0].name,
			options: lastItem.options,
			total: 0,
			price: lastItem.options[0].price,
			totalPrice: 0,
		});
		//
		for (let i = 1; i < totalDays; i++) {
			tempTotalServices.map((item) => {
				_totalServices.push({
					rowNumber: ++rowNumber,
					day: "روز " + getDayTitle(i),
					name: item.name,
					detail: item.options[0].name,
					options: item.options,
					total: 0,
					price: item.options[0].price,
					totalPrice: 0,
				});
			});
		}
		//صبحانه روز آخر
		let firstItem = tempTotalServices[0];
		_totalServices.push({
			rowNumber: ++rowNumber,
			day: "روز " + getDayTitle(totalDays),
			name: "صبحانه",
			detail: firstItem.options[0].name,
			options: firstItem.options,
			total: 0,
			price: firstItem.options[0].price,
			totalPrice: 0,
		});
		//

		setTotalServices(_totalServices);

		stepperRef.current.nextCallback();
	};

	//> getDayTitle
	const getDayTitle = function (day) {
		if (day > 10) return day;
		else
			return [
				"اول",
				"دوم",
				"سوم",
				"چهارم",
				"پنجم",
				"",
				"ششم",
				"هفتم",
				"هشتم",
				"نهم",
				"دهم",
			][day];
	};

	//> cancelDate
	const cancelDate = function () {
		stepperRef.current.prevCallback();
	};

	//> addFamily
	const addFamily = function () {
		setFamiles([
			...families,
			{
				familyType: familyType,
				familyName: familyName,
				familyNationalId: familyNationalId,
			},
		]);
		setFamilyName("");
		setFamilyNationalId("");
	};
	//> removeFamily
	const removeFamily = function (family) {
		setFamiles(families.filter((item) => item !== family));
	};

	//> back
	const back = function () {
		stepperRef.current.prevCallback();
	};

	//> imageTemplate
	const imageTemplate = (image) => {
		return (
			<div className="room-image">
				<Image src={image.imageUrl} alt={image.title} preview />
				<span>{image.title}</span>
			</div>
		);
	};

	//> confirmServices
	const confirmServices = function () {
		stepperRef.current.nextCallback();
	};

	//> confirmProperties
	const confirmProperties = function () {
		stepperRef.current.nextCallback();
	};

	//> addDays
	const addDays = function (date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};

	//> getTotalServicesPrice
	const getTotalServicesPrice = function () {
		let total = 0;
		totalServices.map((item) => (total += item.price * item.total));
		return total.toLocaleString("en-US");
	};

	//> useEffect
	useEffect(() => {
		getData();
	}, []);

	//>
	if (!getToken()) {
		window.location = "/#/login?returnUrl=/add-new-queue";
		return <></>;
	}
	return (
		<>
			<Toast ref={toast} />
			<article className="add-new-queue">
				<header>
					<Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
						<StepperPanel header="شهر اقامت">
							<h2>انتخاب شهر محل اقامت</h2>

							<ul className="locations">
								{cities.map((item) => (
									<li>
										<a onClick={() => selectCity(item.name)}>
											<h3>{item.name}</h3>
											<img src={item.imageUrl} />
										</a>
										<div>
											<label>تعداد اتاق ها</label>
											<strong>{item.total}</strong>
										</div>
									</li>
								))}
							</ul>
						</StepperPanel>
						<StepperPanel header="اقامتگاه">
							<h2>
								<span>انتخاب اقامتگاه در شهر {currentCityName}</span>
								<button className="prev" onClick={(e) => back()}>
									<i className={PrimeIcons.ANGLE_LEFT}></i> مرحله قبلی
								</button>
							</h2>
							{pageType === "list" && (
								<ul className="rooms">
									{rooms.map((item) => (
										<li>
											<a
												href="javascript:void(0);"
												onClick={(item) => selectRoom(item.roomId)}
											>
												<img src={item.imageUrl} />
												<span>{item.name}</span>
											</a>
										</li>
									))}
								</ul>
							)}

							{pageType === "details" && (
								<>
									<ol className="details">
										<li>
											<label>نام</label>
											<span>{roomDetails.name}</span>
										</li>
										<li>
											<label>نام شهر</label>
											<span>{roomDetails.city}</span>
										</li>
										<li>
											<label>آدرس</label>
											<span>{roomDetails.address}</span>
										</li>
									</ol>

									<div className="buttons">
										<Button onClick={(e) => confirmRoom()} severity="success">
											انتخاب اتاق
										</Button>
										<Button onClick={(e) => cancelRoom()} severity="danger">
											انصراف
										</Button>
									</div>

									<Carousel
										value={roomDetails.images}
										numVisible={1}
										numScroll={1}
										itemTemplate={imageTemplate}
										autoplayInterval={5000}
										circular
									/>
									<NeshanMap
										mapKey="web.1c8021a2962c46be925658f642c30c83"
										ref={mapRef}
										center={{
											latitude: 35.7665394,
											longitude: 51.4749824,
										}}
										zoom={14}
										style={{ width: "100%", height: 400 }}
									></NeshanMap>
								</>
							)}
						</StepperPanel>
						<StepperPanel header="ثبت مشخصات">
							<h2>
								<span>ثبت مشخصات همراهان</span>
								<button className="prev" onClick={(e) => back()}>
									<i className={PrimeIcons.ANGLE_LEFT}></i> مرحله قبلی
								</button>
							</h2>

							<div>
								<table className="families">
									<thead>
										<tr>
											<th>ردیف</th>
											<th>نسبت</th>
											<th>نام و نام خانوادگی</th>
											<th>کد ملی</th>
											<th>عملیات</th>
										</tr>
									</thead>
									<tbody>
										{families.map((item) => (
											<tr>
												<td>{++familyRowNumber}</td>
												<td>{item.familyType}</td>
												<td>{item.familyName}</td>
												<td>{item.familyNationalId}</td>
												<td>
													<Button onClick={(e) => removeFamily(item)}>
														حذف
													</Button>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td>+</td>
											<td>
												<select
													value={familyType}
													onChange={(e) => setFamilyType(e.target.value)}
												>
													<option value="همسر">همسر</option>
													<option value="پسر">پسر</option>
													<option value="دختر">دختر</option>
													<option value="پدر">پدر</option>
													<option value="مادر">مادر</option>
													<option value="همراه">همراه</option>
												</select>
											</td>
											<td>
												<input
													type="text"
													maxLength={50}
													value={familyName}
													onChange={(e) => setFamilyName(e.target.value)}
												/>
											</td>
											<td>
												<input
													type="text"
													maxLength={10}
													value={familyNationalId}
													onChange={(e) => setFamilyNationalId(e.target.value)}
												/>
											</td>
											<td>
												<Button
													onClick={(e) => addFamily()}
													disabled={!familyName || !familyNationalId || familyNationalId.length < 10}
												>
													افزودن
												</Button>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>

							<div className="buttons">
								<Button onClick={(e) => confirmProperties()}>
									تایید ادامه
								</Button>
							</div>
						</StepperPanel>
						<StepperPanel header="تاریخ">
							<h2>
								<span>انتخاب تاریخ و مدت اقامت</span>
								<button className="prev" onClick={(e) => back()}>
									<i className={PrimeIcons.ANGLE_LEFT}></i> مرحله قبلی
								</button>
							</h2>
							<div className="date-duration">
								<div>
									<label>تاریخ شروع اقامت</label>
									<DatePicker
										value={startDate}
										onChange={(value) => setStartDate(value)}
										calendar={persian}
										locale={persian_fa}
									/>
								</div>
								<div>
									<label>مدت اقامت (شب)</label>
									<input
										type="number"
										min={1}
										max={3}
										value={totalDays}
										onChange={(e) => setTotalDays(e.target.value)}
									/>
								</div>

								<div>
									<label>تاریخ ورود</label>
									<span>
										{startDate
											? new Date(startDate).toLocaleString("fa-IR", {
													month: "long",
													day: "numeric",
													year: "numeric",
											  }) + " ساعت 2 بعد از ظهر "
											: ""}{" "}
									</span>
								</div>
								<div>
									<label>
										تاریخ خروج ( {totalDays} شب و {Number(totalDays) + 1} روز )
									</label>
									<span>
										{startDate
											? addDays(
													new Date(startDate),
													Number(totalDays)
											  ).toLocaleString("fa-IR", {
													month: "long",
													day: "numeric",
													year: "numeric",
											  }) + " ساعت 10 صبح "
											: ""}
									</span>
								</div>
								<div>
									<label>قیمت این واحد برای {totalDays} شب</label>
									<span>
										{(5000000 * Number(totalDays)).toLocaleString("fa-IR")} ریال
									</span>
								</div>
							</div>
							<div className="buttons">
								<Button onClick={(e) => confirmDate()} severity="success">
									تایید و ادامه
								</Button>
								<Button onClick={(e) => cancelDate()} severity="danger">
									بازگشت به مرحله قبل
								</Button>
							</div>{" "}
						</StepperPanel>
						<StepperPanel header="خدمات">
							<h2>
								<span>
									انتخاب سایر خدمات تعدادی : محاسبه و پیش نمایش قیمت و مبلغ
									بیعانه
								</span>
								<button className="prev" onClick={(e) => back()}>
									<i className={PrimeIcons.ANGLE_LEFT}></i> مرحله قبلی
								</button>
							</h2>
							<div className="services">
								<table>
									<thead>
										<tr>
											<th>ردیف</th>
											<th>روز</th>
											<th>شرح خدمات</th>
											<th>نوع منو</th>
											<th>قیمت</th>
											<th>تعداد</th>
											<th>قیمت کل</th>
										</tr>
									</thead>
									<tbody>
										{totalServices.map((item) => (
											<tr className={item.total > 0 ? "active" : ""}>
												<td>{item.rowNumber}</td>
												<td>{item.day}</td>
												<td>
													<div className="group">
														<a
															className={PrimeIcons.MINUS_CIRCLE}
															onClick={() =>
																updateServiceTotal(item.rowNumber, 0)
															}
														></a>
														{item.name}
														<a
															className={PrimeIcons.PLUS_CIRCLE}
															onClick={() =>
																updateServiceTotal(
																	item.rowNumber,
																	families.length + 1
																)
															}
														></a>
													</div>
												</td>
												<td>
													<select
														value={item.detail}
														onChange={(e) =>
															typeof updateServiceDetail === "function"
																? updateServiceDetail(
																		item.rowNumber,
																		e.target.value,
																		item.options
																  )
																: true
														}
													>
														{item.options.map((oItem) => (
															<option value={oItem.name}>{oItem.name}</option>
														))}
													</select>
												</td>
												<td>{item.price.toLocaleString("en-us")}</td>
												<td>
													<div className="group">
														<a
															className={PrimeIcons.MINUS_CIRCLE}
															onClick={() =>
																updateServiceTotal(
																	item.rowNumber,
																	item.total - 1
																)
															}
														></a>
														<input
															type="number"
															value={item.total}
															min={0}
															max={families.length + 1}
															onChange={(e) =>
																updateServiceTotal(
																	item.rowNumber,
																	e.target.value
																)
															}
														/>
														<a
															className={PrimeIcons.PLUS_CIRCLE}
															onClick={() =>
																updateServiceTotal(
																	item.rowNumber,
																	item.total + 1
																)
															}
														></a>
													</div>
												</td>
												<td>
													{(item.total * item.price).toLocaleString("en-us")}
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td colSpan="6"></td>
											<td>{getTotalServicesPrice()}</td>
										</tr>
									</tfoot>
								</table>
							</div>

							<div className="buttons">
								<Button onClick={(e) => confirmServices()}>
									تایید خدمات و ادامه
								</Button>
							</div>
						</StepperPanel>
						<StepperPanel header="پرداخت بیعانه">
							<h2>
								<span>نمایش پیش فاکتور جهت پرداخت بیعانه</span>
								<button className="prev" onClick={(e) => back()}>
									<i className={PrimeIcons.ANGLE_LEFT}></i> مرحله قبلی
								</button>
							</h2>
							<table className="summary">
								<thead>
									<tr>
										<th>ردیف</th>
										<th>شرح</th>
										<th>قیمت</th>
										<th>تعداد / مدت</th>
										<th>قیمت کل</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{++rowNumber}</td>
										<td>مدت اقامت</td>
										<td>{(5000000).toLocaleString("en-us")}</td>
										<td>{totalDays}</td>
										<td>{(totalDays * 5000000).toLocaleString("en-us")}</td>
									</tr>

									{totalServices.map((item) => (
										<tr>
											<td>{++rowNumber}</td>
											<td>{item.name}</td>
											<td>{item.price?.toLocaleString("en-us")}</td>
											<td>{item.total}</td>
											<td>
												{(item.total * item.price).toLocaleString("en-us")}
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<div className="buttons">
								<Button onClick={(e) => confirmProperties()}>
									تایید پیش فاکتور و پرداخت نهایی
								</Button>
							</div>
						</StepperPanel>
					</Stepper>
				</header>
			</article>
		</>
	);
}
