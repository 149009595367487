import "../styles/store-details.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import ServiceController from "../services/service-controller";
import { Skeleton } from "primereact/skeleton";
import { Carousel } from "primereact/carousel";
import { Map } from "@neshan-maps-platform/ol";
import NeshanMap from "@neshan-maps-platform/react-openlayers";
import { Image } from "primereact/image";
import { PrimeIcons } from "primereact/api";

export default function StoreDetails(props) {
  const { t } = useTranslation();
  const localizer = t;
  const serviceController = new ServiceController();
  const [isLoading, setIsLoading] = useState(false);
  const [store, setStore] = useState({});
  const mapRef = useRef(null);

  const getData = async function () {
    try {
      setIsLoading(true);
      let name = "";
      if (window.location.href.indexOf("?") > 0) {
        let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
        name = urlParams.get("name");
      }
      const storeResult = await serviceController.getStore(name);
      if (storeResult.isSuccess === false) throw storeResult.message;
      setStore(storeResult.value);
    } catch (error) {
      alert(error.toString());
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const imageTemplate = (image) => {
    return (
      <div className="store-image">
        <Image src={image.imageUrl} alt={image.title} preview />
        <span>{image.title}</span>
      </div>
    );
  };

  return (
    <div>
      {isLoading === true && (
        <>
          <div className="flex gap-4 mb-4">
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
          </div>
          <div className="flex gap-4 mb-4">
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
          </div>
          <div className="flex gap-4 mb-4">
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
            <Skeleton height="15rem" className="w-3"></Skeleton>
          </div>
        </>
      )}

      <div className="store-details">
        <h2>
          {store.stateName} - {store.cityName} - {store.area} - {store.name}{" "}
        </h2>
        <strong>{store.summary}</strong>
        <Carousel
          value={store.images}
          numVisible={1}
          numScroll={1}
          itemTemplate={imageTemplate}
          autoplayInterval={5000}
          circular
        />
        <p>{store.notes}</p>
        <ul>
          <li>
            <label>تلفن</label>
            <span>
              {store.tel} - {store.otherTels}
            </span>
          </li>
          <li>
            <label>ساعات کاری</label>
            <span>
              {store.dayOfWork} - از {store.startTime} تا {store.endTime}
            </span>
          </li>
          <li>
            <label>آدرس</label>
            <span>{store.address}</span>
          </li>
        </ul>

        <ol>
          <li>
            <a href={store.webSite} target="_blank">
              <i className={PrimeIcons.GLOBE}></i>
            </a>
          </li>
          <li>
            <a href={store.telegram} target="_blank">
              <i className={PrimeIcons.TELEGRAM}></i>
            </a>
          </li>
          <li>
            <a href={store.instagram} target="_blank">
              <i className={PrimeIcons.INSTAGRAM}></i>
            </a>
          </li>
        </ol>

        <NeshanMap
          mapKey="web.1c8021a2962c46be925658f642c30c83"
          ref={mapRef}
          center={{
            latitude: 35.7665394,
            longitude: 51.4749824,
          }}
          zoom={14}
          style={{ width: "100%", height: 400 }}
        />
      </div>
    </div>
  );
}
