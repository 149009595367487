import "../styles/cardbank.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

export default function CardBank({cardValue,cardOwnerName,onChange}) {
	const { t } = useTranslation();
	const localizer = t;
	const [value, setValue] = useState("");
	const [maskedValue, setMaskedValue] = useState("");
	const [ownerName, setOwnerName] = useState("");
	const [bank, setBank] = useState({ value: "unknown.png" });
	const banks = [
		{
			name: "بانک اقتصاد نوین",
			preNumber: "627412",
			value: "eghtesad-novin.png",
		},
		{ name: "بانک انصار", preNumber: "627381", value: "sepah.png" },
		{ name: "بانک ایران زمین", preNumber: "505785", value: "iran-zamin.png" },
		{ name: "بانک پارسیان", preNumber: "622106", value: "parsian.png" },
		{ name: "بانک پارسیان", preNumber: "639194", value: "parsian.png" },
		{ name: "بانک پارسیان", preNumber: "627884", value: "parsian.png" },
		{ name: "بانک پاسارگاد", preNumber: "639347", value: "pasargad.png" },
		{ name: "بانک پاسارگاد", preNumber: "502229", value: "pasargad.png" },
		{ name: "بانک آینده", preNumber: "636214", value: "ayandeh.png" },
		{ name: "بانک تجارت", preNumber: "627353", value: "tejarat.png" },
		{
			name: "بانک توسعه تعاون",
			preNumber: "502908",
			value: "tosee-taavon.png",
		},
		{
			name: "بانک توسعه صادرات ایران",
			preNumber: "627648",
			value: "tosee-saderat.png",
		},
		{
			name: "بانک توسعه صادرات ایران",
			preNumber: "207177",
			value: "tosee-saderat.png",
		},
		{ name: "بانک حکمت ایرانیان", preNumber: "636949", value: "sepah.png" },
		{ name: "بانک دی", preNumber: "502938", value: "dey.png" },
		{
			name: "بانک رفاه کارگران",
			preNumber: "589463",
			value: "refah-kargaran.png",
		},
		{ name: "بانک سامان", preNumber: "621986", value: "saman.png" },
		{ name: "بانک سپه", preNumber: "589210", value: "sepah.png" },
		{ name: "بانک سرمایه", preNumber: "639607", value: "sarmayeh.png" },
		{ name: "بانک سینا", preNumber: "639346", value: "sina.png" },
		{ name: "بانک شهر", preNumber: "502806", value: "shahr.png" },
		{ name: "بانک صادرات ایران", preNumber: "603769", value: "saderat.png" },
		{
			name: "بانک صنعت و معدن",
			preNumber: "627961",
			value: "sanat-maadan.png",
		},
		{
			name: "بانک قرض الحسنه مهر ایران",
			preNumber: "606373",
			value: "mehr-iran.png",
		},
		{ name: "بانک قوامین", preNumber: "639599", value: "sepah.png" },
		{ name: "بانک کارآفرین", preNumber: "627488", value: "kar-afarin.png" },
		{ name: "بانک کارآفرین", preNumber: "502910", value: "kar-afarin.png" },
		{ name: "بانک کشاورزی", preNumber: "603770", value: "keshavarzi.png" },
		{ name: "بانک کشاورزی", preNumber: "639217", value: "keshavarzi.png" },
		{ name: "بانک گردشگری", preNumber: "505416", value: "gardeshgari.png" },
		{ name: "بانک مرکزی", preNumber: "636795", value: "markazi.png" },
		{ name: "بانک مسکن", preNumber: "628023", value: "maskan.png" },
		{ name: "بانک ملت", preNumber: "610433", value: "mellat.png" },
		{ name: "بانک ملت", preNumber: "991975", value: "mellat.png" },
		{ name: "بانک ملی ایران", preNumber: "603799", value: "melli.png" },
		{ name: "بانک مهر اقتصاد", preNumber: "639370", value: "sepah.png" },
		{ name: "پست بانک ایران", preNumber: "627760", value: "port-bank.png" },
		//{ name: "موسسه اعتباری توسعه", preNumber: "628157", value: "628157" }, -- منحل شده
		{ name: "موسسه اعتباری کوثر", preNumber: "505801", value: "sepah.png" },
	];

	useEffect(() => {
		getData();
	}, [cardValue,cardOwnerName]);
 
	const getData = function () {
		try {
			updateValue(cardValue);
			setOwnerName(cardOwnerName);
		} catch (error) {
			alert(error.toString());
		}
	};

	const updateValue = function (newValue) {
		setValue(newValue);
		setMaskedValue(addDashes(newValue));
		setBank({ value: "unknown.png" });
		if (newValue && newValue.length > 5) {
			let objBank = banks.find(
				(item) => item.preNumber === newValue.substring(0, 6)
			);
			if (objBank) setBank(objBank);
		}
		if (onChange) onChange({ value: newValue });
	};

	const addDashes = function (value) {
		if (!value) return "";
		return (
			value.slice(0, 4) +
			"-" +
			value.slice(4, 8) +
			"-" +
			value.slice(8, 12) +
			"-" +
			value.slice(12, 16)
		);
	};

	return (
		<div className="cardbank">
			<div className="info">
				<img src={"/images/banks/" + bank.value} />
				<strong>{maskedValue}</strong>
				<span>{ownerName}</span>
			</div>
			<InputText
				value={value}
				className="ltr"
				keyfilter="int"
				maxLength={16}
				required
				onChange={(e) => updateValue(e.target.value)}
			/>
		</div>
	);
}
