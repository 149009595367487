import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import PaymentController from "../services/payment-controller";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";

export default function SectionStores(props) {
  const { t } = useTranslation();
  const localizer = t;
  const [stores, setStores] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = function () {
    try {
    
      setStores([
        {
          title: "لوازم تحصیلی ، فرهنگی و هنری",
          imageUrl: "/images/stores/art.jpg",
        },
        { title: "پوشاک", imageUrl: "/images/stores/Portfolio01.png" },
        {
          title: "خانه و آشپزخانه",
          imageUrl: "/images/stores/Portfolio02.png",
        },
        {
          title: "خدمات پزشکی ، سلامت ، زیبایی",
          imageUrl: "/images/stores/Portfolio03.png",
        },
        { title: "مایحتاج روزانه", imageUrl: "/images/stores/mayahtag.jpg" },
        { title: "کافی شاپ", imageUrl: "/images/stores/Portfolio08.png" },
        { title: "سفر و گردشگری", imageUrl: "/images/stores/tourist.png" },
        { title: "خدمات", imageUrl: "/images/stores/car.jpg" },
        {
          title: "مراکز ورزشی – تفریحی",
          imageUrl: "/images/stores/football.jpg",
        },
        { title: "آموزش", imageUrl: "/images/stores/education.jpg" },
        { title: "لوازم شخصی", imageUrl: "/images/stores/Portfolio04.png" },
        { title: "لوازم کودک و نوزاد", imageUrl: "baby-/images/stores/toys.jpg" },
        {
          title: "تعمیرات و خدمات فنی",
          imageUrl: "/images/stores/tamirat.jpg",
        },
        { title: "سایر خدمات", imageUrl: "/images/stores/service.jpg" },
      ]);
    } catch (error) {
      alert(error.toString());
    }
  };

  return (
    <div className="stores">
      <h2>فروشگاه های طرف قرارداد</h2>
      <ul>
        {stores.map((item) => (
          <li>
            <a href={`/#/stores/?cat=${item.title}`}>
              <img src={item.imageUrl} />
              <span>{item.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
