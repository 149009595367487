import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import PaymentController from "../services/payment-controller";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";

export default function SectionSports(props) {
  const { t } = useTranslation();
  const localizer = t;

  return (
    <div>
      <h2>اماکن ورزشی</h2>
      <table className="sport">
            <thead>
              <tr>
                <th colspan="9">
                  <h3>اطلاعات مجموعه های ورزشی و تفریحی کارگران کشور&nbsp;</h3>
                </th>
              </tr>
              <tr>
                <th rowspan="2">
                  <h4>ردیف</h4>
                </th>
                <th rowspan="2">
                  <h4>نام استان&nbsp;</h4>
                </th>
                <th rowspan="2">
                  <h4>تعداد مجموعه</h4>
                </th>
                <th rowspan="2">
                  <h4>متراژ کل</h4>
                </th>
                <th colspan="5">
                  <h4>اماکن و متراژ فضا بر اساس (متر مربع)</h4>
                </th>
              </tr>
              <tr>
                <th>
                  <h4>متراژ سالن چند منظوره</h4>
                </th>
                <th>
                  <h4>تعداد سالن</h4>
                </th>
                <th>
                  <h4>متراژ استخر&nbsp;</h4>
                </th>
                <th>
                  <h4>تعداد استخر</h4>
                </th>
                <th>
                  <h4>متراژ فضای روباز و سایر اماکن&nbsp;</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>آذر شرقی&nbsp;</td>
                <td>13</td>
                <td>268075</td>
                <td>15995</td>
                <td>13</td>
                <td>3830</td>
                <td>3</td>
                <td>32960</td>
              </tr>
              <tr>
                <td>2</td>
                <td>آذر غربی</td>
                <td>5</td>
                <td>157200</td>
                <td>8000</td>
                <td>5</td>
                <td>2430</td>
                <td>2</td>
                <td>36680</td>
              </tr>
              <tr>
                <td>3</td>
                <td>اردبیل</td>
                <td>7</td>
                <td>137035</td>
                <td>9663</td>
                <td>7</td>
                <td>0</td>
                <td>0</td>
                <td>15973</td>
              </tr>
              <tr>
                <td>4</td>
                <td>اصفهان</td>
                <td>12</td>
                <td>345102</td>
                <td>14130</td>
                <td>11</td>
                <td>2790</td>
                <td>3</td>
                <td>68477</td>
              </tr>
              <tr>
                <td>5</td>
                <td>البرز</td>
                <td>2</td>
                <td>287500</td>
                <td>3300</td>
                <td>2</td>
                <td>2850</td>
                <td>1</td>
                <td>19050</td>
              </tr>
              <tr>
                <td>6</td>
                <td>ایلام</td>
                <td>1</td>
                <td>98000</td>
                <td>1476</td>
                <td>1</td>
                <td>2014</td>
                <td>1</td>
                <td>7484</td>
              </tr>
              <tr>
                <td>7</td>
                <td>بوشهر</td>
                <td>3</td>
                <td>74000</td>
                <td>1575</td>
                <td>1</td>
                <td>0</td>
                <td>0</td>
                <td>23138</td>
              </tr>
              <tr>
                <td>8</td>
                <td>تهران</td>
                <td>7</td>
                <td>313000</td>
                <td>13855</td>
                <td>9</td>
                <td>2233</td>
                <td>3</td>
                <td>42483</td>
              </tr>
              <tr>
                <td>9</td>
                <td>چهار محال و بختیاری</td>
                <td>3</td>
                <td>130985</td>
                <td>4140</td>
                <td>3</td>
                <td>3000</td>
                <td>2</td>
                <td>3060</td>
              </tr>
              <tr>
                <td>10</td>
                <td>خراسان جنوبی</td>
                <td>1</td>
                <td>3000</td>
                <td>1200</td>
                <td>1</td>
                <td>0</td>
                <td>0</td>
                <td>40</td>
              </tr>
              <tr>
                <td>11</td>
                <td>خراسان رضوی</td>
                <td>9</td>
                <td>213885</td>
                <td>22020</td>
                <td>11</td>
                <td>4626</td>
                <td>2</td>
                <td>32662</td>
              </tr>
              <tr>
                <td>12</td>
                <td>خراسان شمالی</td>
                <td>5</td>
                <td>259917</td>
                <td>6570</td>
                <td>5</td>
                <td>1365</td>
                <td>1</td>
                <td>10146</td>
              </tr>
              <tr>
                <td>13</td>
                <td>خوزستان&nbsp;</td>
                <td>6</td>
                <td>215899</td>
                <td>8785</td>
                <td>5</td>
                <td>5320</td>
                <td>6</td>
                <td>28835</td>
              </tr>
              <tr>
                <td>14</td>
                <td>زنجان</td>
                <td>6</td>
                <td>195873</td>
                <td>6860</td>
                <td>5</td>
                <td>400</td>
                <td>1</td>
                <td>21219</td>
              </tr>
              <tr>
                <td>15</td>
                <td>سمنان</td>
                <td>7</td>
                <td>303000</td>
                <td>9120</td>
                <td>7</td>
                <td>5680</td>
                <td>3</td>
                <td>48730</td>
              </tr>
              <tr>
                <td>16</td>
                <td>سیستان و بلوچستان</td>
                <td>4</td>
                <td>310000</td>
                <td>5760</td>
                <td>4</td>
                <td>0</td>
                <td>0</td>
                <td>51678</td>
              </tr>
              <tr>
                <td>17</td>
                <td>فارس</td>
                <td>18</td>
                <td>657046</td>
                <td>15051</td>
                <td>12</td>
                <td>3936</td>
                <td>4</td>
                <td>8647</td>
              </tr>
              <tr>
                <td>18</td>
                <td>قزوین</td>
                <td>5</td>
                <td>99343</td>
                <td>5700</td>
                <td>4</td>
                <td>9785</td>
                <td>3</td>
                <td>8500</td>
              </tr>
              <tr>
                <td>19</td>
                <td>قم</td>
                <td>1</td>
                <td>70000</td>
                <td>798</td>
                <td>1</td>
                <td>2048</td>
                <td>1</td>
                <td>24660</td>
              </tr>
              <tr>
                <td>20</td>
                <td>کردستان</td>
                <td>7</td>
                <td>54084</td>
                <td>7700</td>
                <td>4</td>
                <td>1500</td>
                <td>1</td>
                <td>1280</td>
              </tr>
              <tr>
                <td>21</td>
                <td>کرمان</td>
                <td>5</td>
                <td>411566</td>
                <td>9126</td>
                <td>7</td>
                <td>600</td>
                <td>1</td>
                <td>14766</td>
              </tr>
              <tr>
                <td>22</td>
                <td>کرمانشاه</td>
                <td>3</td>
                <td>48000</td>
                <td>3766</td>
                <td>3</td>
                <td>2200</td>
                <td>1</td>
                <td>10304</td>
              </tr>
              <tr>
                <td>23</td>
                <td>کهکیلویه و بویر احمد</td>
                <td>3</td>
                <td>80000</td>
                <td>2700</td>
                <td>2</td>
                <td>2050</td>
                <td>1</td>
                <td>600</td>
              </tr>
              <tr>
                <td>24</td>
                <td>گلستان</td>
                <td>5</td>
                <td>148635</td>
                <td>6628</td>
                <td>5</td>
                <td>1100</td>
                <td>1</td>
                <td>27206</td>
              </tr>
              <tr>
                <td>25</td>
                <td>گیلان</td>
                <td>7</td>
                <td>250000</td>
                <td>12064</td>
                <td>9</td>
                <td>325</td>
                <td>1</td>
                <td>15300</td>
              </tr>
              <tr>
                <td>26</td>
                <td>لرستان</td>
                <td>5</td>
                <td>114000</td>
                <td>6063</td>
                <td>5</td>
                <td>2000</td>
                <td>2</td>
                <td>30880</td>
              </tr>
              <tr>
                <td>27</td>
                <td>مازندران</td>
                <td>6</td>
                <td>119441</td>
                <td>7000</td>
                <td>5</td>
                <td>0</td>
                <td>0</td>
                <td>13579</td>
              </tr>
              <tr>
                <td>28</td>
                <td>مرکزی</td>
                <td>5</td>
                <td>222390</td>
                <td>5756</td>
                <td>5</td>
                <td>3250</td>
                <td>2</td>
                <td>8386</td>
              </tr>
              <tr>
                <td>29</td>
                <td>هرمزگان</td>
                <td>1</td>
                <td>60240</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>1850</td>
              </tr>
              <tr>
                <td>30</td>
                <td>همدان</td>
                <td>7</td>
                <td>103500</td>
                <td>8100</td>
                <td>7</td>
                <td>2085</td>
                <td>2</td>
                <td>29573</td>
              </tr>
              <tr>
                <td>31</td>
                <td>یزد</td>
                <td>9</td>
                <td>348500</td>
                <td>9600</td>
                <td>8</td>
                <td>3500</td>
                <td>2</td>
                <td>92288</td>
              </tr>
              <tr>
                <td colspan="2">جمع کل</td>
                <td>186</td>
                <td>6E+06</td>
                <td>232501</td>
                <td>167</td>
                <td>70917</td>
                <td>50</td>
                <td>730434</td>
              </tr>
            </tbody>
          </table>
    </div>
  );
}
