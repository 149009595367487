import "../styles/levels.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

export default function LevelSilver(props) {
  const { t } = useTranslation();
  const localizer = t;

  return (
    <article className="levels">
      <h2>سطح نقره ای</h2>
      <h3>دهک 8 تا 10</h3>
      <div>
        <p>
          بهرمندی از خدمات تخفیفی در سایر فروشگاه های طرف قرارداد سامانه مشتریان
          کارگران
        </p>
        <p>
          ، بهرمندی از خدمات مجموعه های ورزشی و تفریحی کارگران کشور با 20% تخفیف
        </p>
        <p>و بهرمندی از تخفیف 20% در پرداخت حق عضویت یکساله</p>
        <strong>حق عضویت یکساله : 560 هزار تومان</strong>
        <b>
          با هر خرید از طریق فروشگاه ها و مراکز طرف قرارداد و افزایش امتیاز می
          توانید : 
        </b>
        <ul>
          <li>ارتقا به سطح بالاتر و استفاده از امکانات سطح مربوطه</li>
          <li>
            بهرمندی از جوایزی که طی قرعه کشی های منظم مشخص و ارائه می گردد .
          </li>
          <li>بهره مندی از کمپین های فروش لوازم ورزشی با قیمتی بسیار ویژه</li>
          <li>
            بهرمندی از امکان استفاده از تورهای ورزشی ، تفریحی ، زیارتی و سیاحتی
          </li>
          <li>
            بهرمندی از امکان خرید اعتباری و اقساطی در مراکز و فروشگاه های طرف
            قرارداد
          </li>
        </ul>
      </div>
    </article>
  );
}
