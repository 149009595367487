import "../styles/home.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import PaymentController from "../services/payment-controller";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { TabPanel, TabView } from "primereact/tabview";
import { PrimeIcons } from "primereact/api";

export default function Home(props) {
  const { t } = useTranslation();
  const localizer = t;
  const [banners, setBanners] = useState([]);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = function () {
    try {
      setBanners([
        {
          imageUrl: "/images/banner3.jpg",
          title: "امکانات سامانه",
          notes:
            "ارائه خدمات در مجموعه های ورزشی و تفریحی کارگران کشور",
        },
        {
          imageUrl: "/images/banner4.jpg",
          title: "امکانات اعضا",
          notes:
            "در سامانه جامع باشگاه مشتریان کارگران عضو شوید و از هزاران فروشگاه و مرکز طرف قرارداد خرید کنید و تخفیفات ویژه دریافت نمایید",
        },
        // {
        //   imageUrl: "/images/banner3.jpg",
        //   title: "سال جدید",
        //   notes: "تبریک سال نو",
        // },
        // {
        //   imageUrl: "/images/banner4.jpg",
        //   title: "عید فطر",
        //   notes: "تبریک عید سعید فطر",
        // },
      ]);
    } catch (error) {
      alert(error.toString());
    }
  };

  const bannerTemplate = (banner) => {
    return (
      <div className="banner">
        <img src={banner.imageUrl} alt={banner.title} />
      </div>
    );
  };

  return (
    <>
      <Carousel
        value={banners}
        numVisible={1}
        numScroll={1}
        itemTemplate={bannerTemplate}
        autoplayInterval={3000}
        circular
      />

      <div className="quick-access">
        <h2>دسترسی سریع</h2>

        <ul>
          <li>
            <a href="/#/login">
              <i className="pi pi-sign-in"></i>
              <span>عضویت</span>
              <p>ورود به پرتال</p>
            </a>
          </li>
          <li>
            <a href="/#/payment">
              <i className="pi pi-percentage"></i>
              <span>پرداخت حق عضویت</span>
              <p>با استفاده از امکانات امتیاز بگیرید</p>
            </a>
          </li>
          <li>
            <a href="/#/register">
              <i className="pi pi-user-edit"></i>
              <span>تکمیل پروفایل</span>
              <p>برای استفاده از امکانات بیشتر اطلاعات خود را تکمیل نمایید</p>
            </a>
          </li>
          <li>
            <a href="/#/add-new-queue">
              <i className={PrimeIcons.TICKET}></i>
              <span>رزرو اماکن اقامتی</span>
              <p>برای رزرو اماکن اقامتی وارد شوید</p>
            </a>
          </li>
        </ul>
      </div>

      <ul className="member-types">
        <li>
          <a href="/#/level-bronze">
            <img src="/images/bronze.jpg" />
            <h2>عضویت برنزی</h2>
            <p>عموم مردم</p>
          </a>
        </li>
        <li>
          <a href="/#/level-silver">
            <img src="/images/silver.jpg" />
            <h2>عضویت نقره ای</h2>
            <p>دهک 8 تا 10</p>
          </a>
        </li>
        <li>
          <a href="/#/level-gold">
            <img src="/images/gold.jpg" />
            <h2>عضویت طلایی</h2>
            <p>دهک 4 تا 7</p>
          </a>
        </li>
        <li>
          <a href="/#/level-platinum">
            <img src="/images/platinum.jpg" />
            <h2>عضویت پلاتینیوم</h2>
            <p>دهک 1 تا 3</p>
          </a>
        </li>
      </ul>

      <ul className="sections">
        <li>
          <a href="/#/section-locations">
            <img src="/images/section-locations.jpg" />
            <span>اماکن اقامتی</span>
          </a>
        </li>
        <li>
          <a href="/#/section-sports">
            <img src="/images/section-sports.jpg" />
            <span>مراکز ورزشی</span>
          </a>
        </li>
        <li>
          <a href="/#/section-stores">
            <img src="/images/section-stores.jpg" />
            <span>سایر فروشگاه های طرف قرارداد</span>
          </a>
        </li>
      </ul>
    </>
  );
}
